import React from 'react';
import CardItem from './RenewOffering';
import './renewOfferings.css';

function RenewOfferings() {
    return (
        <div className='renewable-container'>
          <section className='renewable-subscription'>
            <div className='cards__container'>
              <div className='renewable-offerings'>
              <h1 className='renewable-heading'>OFFERINGS</h1>
              </div>
              <div className='cards__wrapper'>
                <ul className='cards__items'>
                  <CardItem
                    src='/images/offerings-01.png'
                    text=''
                    label='Solar Energy Storage'
                    path='/'
                  />
                  <CardItem
                    src='/images/offerings-02.png'
                    text=''
                    label='Wind Energy Storage'
                    path='/'
                  />
                  <CardItem
                    src='/images/offerings-03.png'
                    text=''
                    label='Street Lamp Energy Storage'
                    path='/'
                  /> 
                </ul>
                <div className='renewable-offerings'>
                <p className='renewable-heading'>We are able to offer a turnkey renewable energy solution utilizing state-of-the-art solar PV that energise our unique, Nano-technologically developed battery and storage systems built from advanced Nano-technologically to deliver superior performance. This turnkey solution is suitable for stand-alone or hybrid uses combining with the grid , wind turbines and renewables, specifically Solar PV. Transformers are included in the solution. We also can offer Peak Grid Balancing Battery Solutions to mitigate the peak power consumption needs over high usage hours of the day a short term solution.</p>
                <br/>
                <p className='renewable-heading'>We offer advance containerised Energy Storage Systems (ESS) providing superior performance with high levels of safety and stability. You can enjoy the benefits of low total cost of ownership, higher performance, higher environmental friendliness, low maintenance and more...</p>
                <br/>
                <p className='renewable-heading-01'>Vision</p>
                    <p>
                      Create Future Energy evolution
                      Clean Energy Source
                      Innovative Technology
                      Market Leading Products
                    </p>
                <p className='renewable-heading-01'>Value</p>
                <p>Sustainable Energy for Green and Clean Future</p>
                </div>
              </div>
            </div>
          </section>
        </div>
    )
};

export default RenewOfferings;