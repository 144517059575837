import React from 'react';
import './renewOfferings.css';
import CardItem from './RenewBottom';
//import honeygroupTwo from '../images/Honey-Group-02.png';
//import BatteryStorage from '../images/Feature_Battery_main-920x458.png';

function RenewableBottom01() {
  return (
    <div className='industries'>
      <section className='renewable-subscription-'>
            <div className='cards__container'>
              <div className='renewable-offerings'>
              <h3>In 40ft containers (2MW potential), the ESS comes with installed transformers capable of plugging into distributor substations(or any other input provision). The Installed batteries are as follows</h3>
                <br/>
              </div>
              <div className='cards__wrapper'>
                <ul className='cards__items'>
                  
                  <CardItem
                    src='/images/Containerised-Energy-Storage-02.png'
                    text=''
                    label=''
                    path='/'
                  />
                   
                  <CardItem
                    src='/images/Containerised-Energy-Storage-01.png'
                    text=''
                    label=''
                    path='/'
                  />
                  <CardItem
                    src='/images/Containerised-Energy-Storage-03.png'
                    text=''
                    label=''
                    path='/'
                  />
                </ul>
                
              </div>
              <p>
                <h3>Key feature of the ESS include</h3>
                <br/>
                  <ul>
                      <li>Latest field-proven long life, safe Life battery technology</li>
                      <li>Advanced Industrial design offering highest safet robustness</li>
                      <li>Wide ambient temperature range</li>
                      <li>Complete turnkey solution with transformers included</li>
                      <li>Remote supervision and diagnostic tool</li>
                      <li>Low carbon footprint</li>
                      <li>Modular architecture with high-energy, medium and high-power configurations</li>
                      <li>Proven architecture for high availability of battery management module</li>
                      <li>Sophisticated battery management system for enhanced operability from the grid</li>
                      <li>Safety management system with smoke detection, fire suppression system and alarms</li>
                      <li>Advanced thermal management system with air conditioning and controllable fans for high cooling efficiency and temperature homogeneity</li>
                  </ul>
              </p> 
            </div>
          </section>
         
    
           
                         
  </div>
  );
}

export default RenewableBottom01;