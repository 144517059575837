import React from 'react';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';

function HeroSectionContactUs() {
    return (
        <div className='hero-container-contact-us'>
            <video src='/videos/video-2.mp4' autoPlay loop muted />
            <div className='hero-container-inner'>
            <h1>CONTACT US</h1>

            <p>We have field facilities providing sales and services across Southern Africa.</p>
            <div className='hero-btns'>
                <Button 
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >
                ENQUIRE
                </Button>
            </div>
            </div>
            
        </div>
    )
}

export default HeroSectionContactUs;
