import React from 'react';
import './Footer.css';

function RenewableHeadline() {
  return (
    <div className='renewable-container'>
      <section className='renewable-subscription'>
        <p className='renewable-subscription-heading'>
          ENERGY STORAGE SOLUTION BRINGS LOW TOTAL COST OWNERSHIP, LOW MAINTANANCE, HIGH OPERATIONAL RELIABILITY, OUTSTANDING ENERGY EFFICIENCY, RENEWABLE POWER AND MORE
        </p>
      </section>
    </div>
  );
}

export default RenewableHeadline;