import React from 'react';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection() {
    return (
        <div className='hero-container'>
            <video src='/videos/video-2.mp4' autoPlay loop muted />
            <div className='hero-container-inner'>
            
            <h1>We are a 100% Black-Owned and managed with a level 1 B-BBEE Status.</h1>
            <p>We manufacture Rail Bottom Discharge Valves with more than 70% of local content required by the industry norms.</p>
            <div className='hero-btns'>
            
            <Link to='/products#' className='btn-mobile'>
                <Button 
                    className='btns'
                    buttonStyle='btn--medium'
                    buttonSize='btn--large'
                >
                EXPLORE MORE
                </Button>
            </Link>
            </div>
            </div>
            
        </div>
    )
}

export default HeroSection;
