import React from 'react';
import './Products.css';
import { Link } from 'react-router-dom';

function ProductItem(props) {
  return (
    <>
      <li className='products__item'>
        <Link className='products__item__link' to={props.path}>
          <figure className='products__item__pic-wrap' data-category={props.label}>
            <img 
              className='products__item__img' 
              alt='Products'
              src={props.src}
              />
          </figure>
          <div className='products_item_info'>
              <h1 className='products__item_text'>{props.text}</h1>
          </div>
        </Link>
      </li> 
    </>
  );
}

export default ProductItem;