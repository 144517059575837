import React from 'react'
import '../../App.css';
import Cards from '../Cards';
import News from '../News';
import Footer from '../Footer';
import HeroSection from '../HeroSection';
import { newslist } from '../Newslist';
import InfoSection from '../InfoSection';
import { InfoData} from '../../data/InfoData';
/*import { InfoDataTwo } from '../../data/InfoDataTwo';*/


function Home() {
    return (
        <>
            <HeroSection />
            <Cards />
            <News newslist={newslist} />
            
            <InfoSection {...InfoData}/>
           {/*<InfoSection {...InfoDataTwo}/>*/} 
            
     <Footer />
        </>
    )    
}
export default Home;