import React from 'react';
import '../Renewable.css';
//import RenewableMain from '../RenewableMain';
import Footer from '../Footer';
import HeroSectionRenewable from '../HeroSectionRenewable';
import RenewableTop from '../RenewableTop';
import RenewableHeadline from '../RenewableHeadline';
import RenewableBottom from '../RenewableBottom';
//import RenewableHeadlineMid from '../RenewableHeadlineMid';
import RenewOfferings from '../RenewOfferings';
import RenewImage from '../RenewImage';
import RenewableBottom01 from '../RenewableBottom01';


function Renewable() {
    return (
        <div>
            <HeroSectionRenewable />
            <RenewableTop />
            <RenewableHeadline />
            <RenewImage />
            {/*<RenewableMain />*/}
            <RenewOfferings />
            {/*<RenewableHeadlineMid />*/}
            <RenewableBottom />
            <RenewableBottom01 />
            <Footer />
        </div>
    )    
}
export default Renewable;