import React from 'react';
import './Cards.css';
//import { Button } from './Button';

function Cards() {
  return (
    <div className='cards'>
      
      <h2>As a 100% Black-owned business, we allow our customers to claim 135% of their spending towards their preferential procurement. In that, we believe that the Broad-Based Black Economic Empowerment code of Good Practice (B-BBEE) seeks to accelerate and fast track the re-entry of the previously marginalized communities into the mainstream of the economy.</h2>
      <br/>
      <h2>Furthermore, it endeavors to transform the South African economy to enable the meaningful participation of the black people, women, and rural communities in the mainstream of the economy in a manner that has a positive impact on employment, income redistribution, structural readjustment, and economic growth.</h2>
      <br/>
      <h2>Kgabo Engineering currently manufactures Rail Bottom discharge valves with more than 70% of local content required by the industry norms. Kgabo Engineering is also an official distributor for LAAP - Southern Africa and other specialized products and services, which includes Fibre Optic Cables, Bus Automation, Fasteners, Hoses, Couplings, Pressure Relief Valves, Automotive Harnesses, Solar Power Renewable energy, Power Energy Batteries, etc.</h2>    
      <br/>
      </div>
  );
}

export default Cards;