import React from 'react';
import { Link } from 'react-router-dom';

function RenewBottom(props) {
    return (
        <>
            <li className='cards__item'>
                <Link className='cards__item__link' to={props.path}>
                        <img 
                            className='cards__item__img'
                            alt=''  
                            src={props.src}
                        />
                </Link>
            </li> 
        </>
    )
}

export default RenewBottom;
