import React, { useState }  from 'react';
import { Link } from  'react-router-dom';
import { Button } from './Button';
import './Navbar.css';
import logo from './kgabo-logo.PNG';

function Navbar() {
    const [click, setClick] = useState (false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick (!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
               <div className='navbar-container'>
                    <Link to='/' className='navbar-logo'>
                    <img src={logo} className="App-logo" alt="logo" />
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={ click ? 'fas fa-times' : 'fa fa-bars'}></i>
                    </div>
                    <ul className={ click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/about-us' className='nav-links' onClick={closeMobileMenu}>
                                About Us
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/products' className='nav-links' onClick={closeMobileMenu}>
                                Products
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/industries' className='nav-links' onClick={closeMobileMenu}>
                                Industries
                            </Link>
                        </li>
                    </ul>
               </div>
               <div className='çontact-number'>+ 27 (11) 201 2107</div>
               <Link to='/contact-us' className='btn-mobile'>
               {button && <Button buttonStyle='btn--primary'>CONTACT US</Button>}
               </Link>
            </nav>
        </>
    )
}

export default Navbar;
