import React from "react";
import './Maps.css';


function Maps() {
    return (

        <div className="maps-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3586.2924053170104!2d28.137645988319633!3d-25.99123718968461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e956e6cf07bbb35%3A0x37da8a2b466b1228!2sGround%20Floor%2C%20Block%20C%2C%2049%20New%20Rd%2C%20Grand%20Central%2C%20Midrand%2C%201685!5e0!3m2!1sen!2sza!4v1637144763490!5m2!1sen!2sza" width="100%" height="500" title="Kgabo Location" allowfullscreen="" loading="lazy" />
        </div>
    )
  }


export default Maps;

