import React from 'react'
import '../../App.css';
import Cards from '../Cards';
import RequestForm from '../RequestForm';
import Footer from '../Footer';
import HeroSection from '../HeroSection';

function SignUp() {
    return (
        <div>
            <HeroSection />
            <Cards />
            <RequestForm />
            <Footer />
        </div>
    )    
}
export default SignUp;