import React from 'react'
import '../../App.css';
//import IndustriesMain from '../IndustriesMain';
import Footer from '../Footer';
import HeroSectionIndustries from '../HeroSectionIndustries';
import { newslist } from '../Newslist';
import News from '../News';

function Industries() {
    return (
        <div>
            <HeroSectionIndustries />
            {/*<IndustriesMain />*/}
            <News newslist={newslist} />
            <Footer />
        </div>
    )    
}
export default Industries;