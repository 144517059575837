export const newslist = [
    {
        id: 1,
        title: '',
        headline: 'South Africa Falls To Third Place As Tanzania Drops Out Of The Top 10',
        description: 'Sa’s Fall To Third Place In Rmb’s Where To Invest In Africa Report Reflects The Country’s Constrained Levels Of Growth, But Also Highlights That It Remains Afri…'
    },
    {
        id: 2,
        title: '',
        headline: 'South Africa Falls To Third Place As Tanzania Drops Out Of The Top 10',
        description: 'Sa’s Fall To Third Place In Rmb’s Where To Invest In Africa Report Reflects The Country’s Constrained Levels Of Growth, But Also Highlights That It Remains Afri…'
    },
    {
        id: 3,
        title: '',
        headline: 'South Africa Falls To Third Place As Tanzania Drops Out Of The Top 10',
        description: 'Sa’s Fall To Third Place In Rmb’s Where To Invest In Africa Report Reflects The Country’s Constrained Levels Of Growth, But Also Highlights That It Remains Afri…'
    },
    {
        id: 4,
        title: '',
        headline: 'South Africa Falls To Third Place As Tanzania Drops Out Of The Top 10',
        description: 'Sa’s Fall To Third Place In Rmb’s Where To Invest In Africa Report Reflects The Country’s Constrained Levels Of Growth, But Also Highlights That It Remains Afri…'
    },
    {
        id: 5,
        title: '',
        headline: 'South Africa Falls To Third Place As Tanzania Drops Out Of The Top 10',
        description: 'Sa’s Fall To Third Place In Rmb’s Where To Invest In Africa Report Reflects The Country’s Constrained Levels Of Growth, But Also Highlights That It Remains Afri…'
    }
]
export default newslist;