import React from 'react'
import CardItem from './RenewGrid';
//import './News.css';

function RenewImage() {
    return (
        <div className='industries'>
            <div className='cards__container'>
              <div className='cards__wrapper'>
                <ul className='cards__items'>
                  <CardItem
                    src='/images/Honey-Group-01-a.png'
                    text=''
                    label='Renewable Energy'
                    path='/renewable-energy'
                  />
                  <CardItem
                    src='/images/Honey-Group-01-b.png'
                    text=''
                    label='Railway'
                    path='/railway'
                  />
                  <CardItem
                    src='/images/Honey Group 01-c.png'
                    text=''
                    label='Petrochemical'
                    path='/petrochemical'
                  /> 
                </ul>
                
                <p>South African specifically being the economic hub of the African continent must have a strong supporting infrastructure that will enable growth and future development for our business and communities. </p>
      <p>
      This approach led to our partnership with, a high-tech innovation company offering world leading solutions in power generation, storage and supply, enabling baseload generation, mid-merit and Grid Balancing peak generation solutions.</p>
      
      <p>Our success is the result of our close attention to details and addressing our customers' key, unique requirements. We have built and maintained a renowned reputation for excellence in our industry as well as in our supply of sought-after equipment and services through out South Africa and into Africa</p>
      <br/>
              </div>
            </div>
        </div>
    )
};

export default RenewImage;