import React from 'react'
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';
import HeroSectionIndustries from '../HeroSectionIndustries';

function Industries() {
    return (
        <div>
            <HeroSectionIndustries />
            <Cards />
            <Footer />
        </div>
    )    
}
export default Industries;