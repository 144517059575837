import React from 'react';
import './Footer.css';

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 2,
          width: 350,
          margin: 10
      }}
  />
);

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        
        
        <div className='input-areas'>
          <h2 className='footer-quick-links'>QUALITY AND SAFETY</h2>
          <ColoredLine />
        </div>
        <p className='footer-subscription-heading'>
        All our products are compliant with stringent local and international oversight standards to meet the requirements and demands of our loyal clients.
        </p>
      </section>
    </div>
  );
}

export default Footer;