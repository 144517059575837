import React from 'react';
import './Footer.css';
//import { Button } from './Button';
import { Link } from 'react-router-dom';

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 2,
          width: 350,
          margin: 10
      }}
  />
);

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          We trust the information provided will be informative and useful.
        </p>
        <p className='footer-subscription-text'>
          At Kgabo Engineering we believe in bringing you the best service available. 
        </p>
        <div className='input-areas'>
          <h2 className='footer-quick-links'>Quick Links</h2>
          <ColoredLine />
        </div>
      </section>
      
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          
          <div class='footer-link-items'>
           
            <Link to='/products'>Tanker Road Emergency Valves</Link>
            <Link to='/products'>Railway Emergency Bottom Valves</Link>
            <Link to='/products'>Hoses</Link>
            <Link to='/products'>Couplings </Link>
          </div>
          <div class='footer-link-items'>
            <h2>{' '}</h2>
            <Link to='/products'>Adaptors </Link>
            <Link to='/products'>Fuel and Chemical Composite Hoses </Link>
            <Link to='/products'>Water Fuel and Chemical Flow meters </Link>
            <Link to='/products'>Fasteners </Link>
            
          </div>
          <div class='footer-link-items'>
            <Link to='/products'>Liquid Loading Arms-fuel and Industrial Application </Link>
            <Link to='/products'>Electric Pumps </Link>
            <Link to='/products'>Pipe Repair Clamps </Link>
          </div>
        </div>
        
      </div>
      <section class='footer-subscription'>
        <p className='footer-quick-links'>Copyright © 2021 - Kgabo Engineering</p>
        </section>
    </div>
  );
}

export default Footer;