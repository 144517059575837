import React from 'react';
import './VisionValues.css';
import LapaLogo from '../images/Lapp-logo.png';


function AuotomotiveMain() {
  return (
    <div className='cards'>
      
      <h2><img src={LapaLogo} alt="Lapa Logo" /></h2>
      <h2>OFFICIAL DISTRIBUTOR IN SOUTHERN AFRICA</h2>
      <br/>
      <p>Kgabo Africa Engineering (Pty) Ltd has been appointed as an official Distributor and Stockist of products offered by LAPP Southern Africa</p>
      <br />
      <p>The  LAPP  “Orange  Letter  Distributor”  (O.L.D)  establishes  Authorised  Product Centres for the LAPP brand of automotive harnesses, cables and accessories</p>
      <p>“OLD’s” form an elite network of wholesale / retail sales points and solution centres that offer the highest standard of LAPP brand Product Knowledge / Product Availability, and Outstanding Cable Connectivity and Accessory Solutions.</p>
      <p>These Elite Networks carry a full range of products that best suits the OLD’s route to market and customer base while enhancing LAPP’s Market penetration.</p>
      <p>The Elite Network chooses LAPP Southern Africa (Pty) Ltd as their preferred partner for all their specialized Cable and Accessory Supplies  – as per the LAPP catalogue range. </p>
      <br/>
      <h2>Benefits for supporting a LAPP “Orange Letter Dealer”:</h2>
      <br/>
      <ul>
        <li>Regional access and availability of LAPP and a Range of Third Party Cable Products </li>
        <li>Providing an 80% order fill rate in the first 12 months’ period, rising to a 90% for the 
period there after.</li>
        <li>LAPP envisages managing the inventory and service levels on an OTIF (On time and 
        in full delivery basis) using incident reporting as an internal continuous improvement 
        measurement tool to improve stock availability for our distributors and their customers. 
        </li>
        <li>Harnessed  solutions,  pre-populated  energy  chains  and  plug  and  play  solutions  are 
available on request. 
        </li>
        <li>Access to on-site technical expertise locally and abroad if deemed necessary. 
        </li>
        <li>Marketing  Support  in  the  Form  of  Product  Training  and  Digital  &  Print  Marketing 
Support Material.</li>
      </ul>
    </div>
  );
}

export default AuotomotiveMain;