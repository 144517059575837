import React from 'react'
import '../../App.css';
import AboutTop from '../AboutTop';
import Footer from '../Footer';
import HeroSectionAbout from '../HeroSectionAbout';
//import AboutMain from '../AboutMain';
import AboutHeadline from '../AboutHeadline';
import VisionValues from '../VisionValues';

function AboutUs() {
    return (
        <div>
            <HeroSectionAbout />
            <AboutTop />
            <AboutHeadline />
            <VisionValues />
          
            <Footer />
        </div>
    )    
}
export default AboutUs;