import ImageOne from '../images/metering-systems.jpg';


export const InfoData = {
    heading: 'Liquid Controls Metering Systems',
    paragraphOne: 'We also have the following to offer',
    paragraphTwo: 'Digital Meter Turbine, Mechanical Meter, Nutating Disk, Mechanical Meter, Oval Gear  GPI Fuel, Aluminium housing GPI Water, Polyprop housing, GPI Chemical, Stainless Steel housing, Aquametro Meters for burner applications.',
    buttonLabel: 'Explore More',
    image: ImageOne,
    reverse: false,
    delay: 100
};