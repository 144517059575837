import React from 'react'
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';
import HeroSectionPetrochemical from '../HeroSectionPetrochemical';

function Industries() {
    return (
        <div>
            <HeroSectionPetrochemical />
            <Cards />
            <Footer />
        </div>
    )    
}
export default Industries;