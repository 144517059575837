import React from 'react'
import '../../App.css';
import AuotomotiveMain from '../AuotomotiveMain';
//import RequestForm from '../RequestForm';
import Footer from '../Footer';
import HeroSectionAutomotive from '../HeroSectionAutomotive';

function Automotive() {
    return (
        <div>
            <HeroSectionAutomotive />
            <AuotomotiveMain />
            
            <Footer />
        </div>
    )    
}
export default Automotive;

