import React from 'react';
import { Link } from 'react-router-dom';

function VisionValueItem(props) {
    return (
        <>
            <li className='vision_values_item'>
                <Link className='vision_values_item__link' to={props.path}>
                    <h1>{props.label}</h1>
                    <div className='vision_values_item__info'>
                        <p className='vision_values_item__text'>{props.text}</p>
                    </div>
                </Link>
            </li> 
        </>
    )
}

export default VisionValueItem;
