import React from 'react'
import CardItem from './CardItem';
import './News.css';

function News() {
    return (
        <div className='industries'>
            <h2>We Provide Innovative Solutions</h2>
            <br/>
            <hr/>
            <br/>
            <h3>As a strategic Exempted Micro Enterprise, (EME) a local manufacturing company of railway tank wagon bottom discharge valves and exclusive distributor of renewable power energy, automotive accessories, mining products, petrochemical products and telecommunication products.
</h3>
            <div className='cards__container'>
              <div className='cards__wrapper'>
                <ul className='cards__items'>
                  <CardItem
                    src='/images/renewable-energy.jpg'
                    text=''
                    label='Renewable Energy'
                    path='/renewable-energy#top-up'
                  />
                  <CardItem
                    src='/images/railway.jpg'
                    text=''
                    label='Railway'
                    path='/railway#'
                  />
                  <CardItem
                    src='/images/petrochemical.jpg'
                    text=''
                    label='Petrochemical'
                    path='/petrochemical#'
                  />
                  
                </ul>
                <ul className='cards__items'>
                  <CardItem
                    src='/images/telecommunications.jpg'
                    text=''
                    label='Telecommunication'
                    path='/telecommunication#'
                  />
                  <CardItem
                    src='/images/mining.jpg'
                    text=''
                    label='Mining'
                    path='/mining#'
                  />
                  <CardItem
                    src='/images/automotive.jpg'
                    text=''
                    label='Automotive'
                    path='/automotive#'
                  />
                </ul>
                <h2>Previously black disadvantaged young business people who started the business with hope rather than an injection of capital to Kgabo.</h2>
              </div>
            </div>
        </div>
    )
};

export default News;