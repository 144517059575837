import React from 'react'
import '../../App.css';
//import Cards from '../Cards';
import Footer from '../Footer';
import HeroSectionProducts from '../HeroSectionProducts';
import ProductsMain from '../ProductsMain';

function Products() {
    return (
        <div>
            <HeroSectionProducts />
            <ProductsMain />
            <Footer />
        </div>
    )    
}
export default Products;