import React from 'react'
import CardItem from './VisionValueItem';
import './VisionValues.css';

function VisionValues({ newslist }) {
    return (
        <div className='vision__values'>
            
            <div className='vision_values_container'>
        <div className='vision_values_wrapper'>
          <ul className='vision_values_items'>
            <CardItem
              src=''
              text='To become an Orgnisation that delivers on its promises to our valued clients.'
              label='OUR VISION'
              path='/'
            />
            <CardItem
              src=''
              text='To support the growth of the South African economy and to create job opportunities by increasing local content percentage in everything we do to strategically align with the broader Government objectives.'
              label='OUR MISSION'
              path='/'
            />
            <CardItem
              src=''
              text='We are customer focused, with quality and safety as a top priority. We see ourselves as courageous and open. Performance and constant improvement drive us and we highly value the principle of being reliable.'
              label='OUR VALUES'
              path='/'
            />
            
          </ul>
        </div>
      </div>
        </div>
    )
};

export default VisionValues;