import React from 'react';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';

function HeroSectionProducts() {
    return (
        <div className='hero-container-products'>
            <div className='hero-container-inner'>
            <h1>Products and Services</h1>
            
            <div className='h2background'>
            <p>We specialize in offering best-in-class products such as loading arms, ground monitors, meters, and pumping systems.</p>
            </div>
            <div className='hero-btns'>
                <Button 
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                >
                ENQUIRE
                </Button>
            </div>
            </div>
            
        </div>
    )
}

export default HeroSectionProducts;
