import React from 'react'
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';
import HeroSectionMining from '../HeroSectionMining';

function Mining() {
    return (
        <div>
            <HeroSectionMining />
            <Cards />
            <Footer />
        </div>
    )    
}
export default Mining;