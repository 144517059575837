import React from 'react'
import { Button } from './Button';
import './RequestForm.css'

function RequestForm() {
    return (
        <div className='input-areas cards'>
          <form>
            <div className='request-form'>
              <div className="request-form-group">
                  <label htmlFor="name" className="form-label">Enter your name:</label>
                  <br />
                  <input
                          className='request-input'
                          name='name'
                          type=''
                          placeholder='Name'
                        />
              </div>
              <div className="request-form-group">
                  <label htmlFor="name">Enter your Surname:</label>
                  <br />
                  <input
                          className='request-input'
                          name='name'
                          type=''
                          placeholder='Surname'
                        />
              </div>
            </div>
              <div className="request-form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <br />
                  <input
                              className='request-input'
                              name='email'
                              type='email'
                              placeholder='Email Address'
                            />
              </div>
              <div className="request-form-group">
                  <label htmlFor="message">Enter your Designation:</label>
                  <br />
                  <input
                              className='request-input'
                              name='designation'
                              type=''
                              placeholder='Title'
                            />
              </div>
              <div className="request-form-group">
                  <label htmlFor="message">Enter your Company:</label>
                  <br />
                  <input
                              className='request-input'
                              name='company'
                              type=''
                              placeholder='Company'
                            />
              </div>
              <Button buttonStyle='btn--outline'>REQUEST THE REPORT</Button>
          </form>

          


        </div>
    )
}

export default RequestForm;
