import React from 'react';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';

function HeroSectionTelecommunication() {
    return (
        <div className='hero-container-telecommunication'>
            {/** <video src='/videos/video-1.mp4' autoPlay loop muted />*/}
            <div className='hero-container-inner'>
            <h1>Telecommunication</h1>
            
            <p>We specialise in offering best-in-class products such as loading arms, ground monitors, meters and pumping systems.</p>
            <div className='hero-btns'>
                <Button 
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                >
                ENQUIRE
                </Button>
            </div>
            </div>
            
        </div>
    )
}

export default HeroSectionTelecommunication;
