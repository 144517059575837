import React from 'react';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';

function HeroSectionIndustries() {
    return (
        <div className='hero-container-industries'>
            <div className='hero-container-inner'>
            <h1>Industries</h1>
            <p>Tanker Road Emergency Valves, Railway Emergency Bottom Valves, Hoses, Couplings, Adaptors, Fuel and Chemical Composite Hoses, Water Fuel and Chemical flow meters, Fasteners, Liquid loading arms-fuel and industrial application, Electric pumps, Pipe Repair Clamps, etc.</p>
            <div className='hero-btns'>
                <Button 
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                >
                ENQUIRE
                </Button>
            </div>
            </div>
            
        </div>
    )
}

export default HeroSectionIndustries;
