import React from 'react';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';

function HeroSectionAbout() {
    return (
        <div className='hero-container-about-us'>
            <video src='/videos/video-1.mp4' autoPlay loop muted />
            <div className='hero-container-inner'>
            <h1>About Us</h1>
            
            <p>We are customer-focused, with quality and safety as a top priority. We see ourselves as courageous and open. Performance and constant improvement drive us, and we highly value the principle of being reliable.</p>
            <div className='hero-btns'>
                <Button 
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                >
                ENQUIRE
                </Button>
            </div>
            </div>
            
        </div>
    )
}

export default HeroSectionAbout;
