import React from 'react'
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';
import HeroSectionTelecommunication from '../HeroSectionTelecommunication';

function Telecommunication() {
    return (
        <div>
            <HeroSectionTelecommunication />
            <Cards />
            <Footer />
        </div>
    )    
}
export default Telecommunication;