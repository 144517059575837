import React from 'react';
import './Cards.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Cards() {
  return (
    <div className='cards'>
      <h2>We are customer-focused, with quality and safety as a top priority. Performance and constant improvement drive us and we highly value the principle of being reliable.</h2>
      <br />
      <h1>As a strategic Exempted Micro Enterprise (EME) a local manufacturer company and also an official distributor, We offer the following products to the following industries: Rail, Petrochemical, Telecommunication, Power Energy and Mining, etc.</h1>
      
      <div className='cards__container'>
        <div><br/><br/></div>
        <Link to='/about-us' className='btn-mobile'>
           <Button buttonStyle='btn--outline'>MORE ABOUT US</Button>
           </Link>
      </div>
    </div>
  );
}

export default Cards;