import React from 'react';
import './Renewable.css';
//import honeygroupTwo from '../images/Honey-Group-02.png';
//import BatteryStorage from '../images/Feature_Battery_main-920x458.png';

function RenewableBottom() {
  return (
    <div>
      <div className='cards '>
          <h2>
            This turnkey Solution therefore enables delivery of the containerised solution, in the desired quantities(ie it is easily and effectively scalable), with easy installtion requirements in conjuction with typically a solar PV farm. This provides a powerful response to "immediate/short-term" power requirements
          </h2>
      </div>

          <div className='hero-container-renewable-battery-storage'>
            <div className='hero-container-inner-renew'>
            
            <h1 className='blue-color'>The Megawatt containerized Energy Storage Systems</h1>
            <p>The Megawatt containerized Energy Storage Systems (ESS) offer advanced power supply with superior perfomance, high levels of safety and stability and flexibility</p>
            
            </div>
          </div>
    </div>
  );
}

export default RenewableBottom;