import React from 'react';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';

function HeroSectionRenewable() {
    return (
        <div id='top-up' className='hero-container-renewable'>
            <div className='hero-container-inner'>
            <h1>Renewable Energy</h1>
            
            <p>We specialise in Renewable Energy that is collected from renewable resources that are naturally replenished on a human timescale. </p>
            <div className='hero-btns'>
                <Button 
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                >
                ENQUIRE
                </Button>
            </div>
            </div>
            
        </div>
    )
}

export default HeroSectionRenewable;
