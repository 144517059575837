import React from 'react';
import './Cards.css';
//import { Button } from './Button';

function RenewableTop() {
  return (
    <div className='cards'>
      <h2>Kgabo Engineering, has secured a distribution mandate for the supply of Solar Power Energy storage solutions and complementary solar panelling equipment, thereby becoming the strategic Distribution Partner to the Southern African market.</h2>
      <br />
      <p>Kgabo Engineering (PTY) Ltd, started in 2014 and is a 100 Black owned and Managed South African Company. The company focuses on various Industrial related equipment as involved with various industrial sectors, for which Renewable Energy has become a key focus.
      </p>
      <p>
      We offer 3 Solutions consisting of Portable Battery Solution, Battery Solution and Containerised Battery Solution to indicate a simple understanding of the system, the scalability and structure with a 6MW solution for example. 
This below system is fully scalable allowing us to increase the energy capacity for both power storage(battery) or Solar (PV) power generation as per your requirements requested. We also can offer Peak Grid Balancing Battery Solutions to mitigate the Peak power consumption need over high usage hours of the day as a short term solution.
      </p>
      <p>
      Based on modular design, our-single units can be easily combined to meet power and capacity requirements from 2MW to multi-megawatts of output. These units are designed and delivered in 40ft containers with a single 40ft container capable of storing and distributing 2MW of power, these container solution can be increases up to 400MW in total if required.
      </p>
      <br/>
     
      <div className='cards__container'>
        <h2>Containerised LFP Energy Storage and Supply</h2>
      </div> 
    </div>
  );
}

export default RenewableTop;