import React from 'react';
import ProductItem from './ProductItem';


function ProductsMain() {
  return (
    <div className='products__main'>
      <h2>As a strategic Exempted Micro Enterprise, (EME) a local manufacturing company of railway Tank Wagon Bottom Discharge Valves and exclusive distributor of renewable power energy, automotive harnesses accessories, mining products, petrochemical products, and telecommunication products.</h2>
            <div className='products__container'>
                <div className='products__wrapper'>
                    <ul className='products__items'>
                        <ProductItem 
                          src='images/railway.jpg'
                          text=' This discharge valve is attached to the bottom portion of the barrel assembly and performs as an intermediary valve that allows pumped fluid into the annulus between the outside of the pump and the tubing wall'
                          label='BOTTOM DISCHARGE VALVE'
                          path='/products'
                        />
                        <ProductItem 
                          src='images/19322-11127854.jpg'
                          text='Dry disconnect couplings have been proven as a successful technology to help protect workers and the environment in the transfer of hazardous materials.'
                          label='DRY DISCONNECT COUPLINGS'
                          path='/products'
                        />
                    </ul>
                    <ul className='products__items'>
                        <ProductItem 
                          src='images/metering-systems.jpg'
                          text='METERING SYSTEMS'
                          label='Our Products'
                          path='/products'
                        />
                        <ProductItem 
                          src='images/accessories.jpg'
                          text='FLEXIMARK MARKING SYSTEMS'
                          label='Our Products'
                          path='/products'
                        />
                        <ProductItem 
                          src='images/Mining-Drill-Bits.png'
                          text='Mining Drill Bits'
                          label='Our Products'
                          path='/products'
                        />
                    </ul>
                    <ul className='products__items'>
                        <ProductItem 
                          src='images/parts-01.jpg'
                          text='Reliable connector for industrial applications, safe and removable connection in machine building, control cabinets and in outdoor'
                          label='EPIC INDUSTRIAL CONNECTORS'
                          path='/products'
                        />
                        <ProductItem 
                          src='images/vapour-recovery-adapter.jpeg'
                          text='Designed to maximize effciency and service performance of vapour recovery with a minimum pressure drop'
                          label='VAPOUR RECOVERY ADAPTOR'
                          path='/products'
                        />
                    </ul>
                    <ul className='products__items'>
                        
                        <ProductItem 
                          src='images/Automotive-Harnesses.jpg'
                          text='Automotive Harnesses'
                          label='Our Products'
                          path='/products'
                        />
                        <ProductItem 
                          src='images/stainless-steel-braided.jpg'
                          text='Stainless Steel Braided'
                          label='Our Products'
                          path='/products'
                        />
                        <ProductItem 
                          src='images/Diaphragm-Pump-02.jpg'
                          text='Diaphragm Pump'
                          label='Our Products'
                          path='/products'
                        />
                    </ul>
                    <ul className='products__items'>
                        
                        <ProductItem 
                          src='images/Mining-Crusher.png'
                          text='Mining Crusher'
                          label='Our Products'
                          path='/products'
                        />
                        <ProductItem 
                          src='images/renewable-energy.jpg'
                          text='Solar Panels'
                          label='Our Products'
                          path='/products'
                        />
                         <ProductItem 
                          src='images/solar-battery-container.jpg'
                          text='Solar Battery Container Storage'
                          label='Our Products'
                          path='/products'
                        />
                    </ul>
                    
                    <ul className='products__items'>
                    <ProductItem 
                          src='images/Battery-Tower.jpg'
                          text='Telecommunication Battery Tower'
                          label='Our Products'
                          path='/products'
                        />
                    </ul>
                  </div>
            </div>
    </div>
  );
}

export default ProductsMain;