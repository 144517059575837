import React from 'react'
import CardItem from './ContactDetailsItem';
import './VisionValues.css';

function ContactDetails({ newslist }) {
    return (
        <div className='vision__values'>
            <div className='vision_values_container'>
            <h2>At Kgabo Engineering we believe in bringing you the best service available.</h2>
            <br/> 
            <p>In-house training for all our technicians ensure your products are acquired professionally and efficiently.</p>
            <p>We have field facilities providing sales and services across Southern Africa.</p>
            <br/>
            <h2>Send Us a Message!</h2>
            <p>One of our sales specialists will get back to you as soon as possible.</p>
                <div className='vision_values_wrapper'>
                  <ul className='vision_values_items'>
                    <CardItem
                      src=''
                      text='Ground Floor, Block C
                      49 New Rd, Grand Central, Midrand, 1685, South Africa'
                      fax=''
                      label='Head Office'
                      path='/'
                    />
                    <CardItem
                      src=''
                      text='Tel :	+ 27 (11) 201 2107'
                      fax='Fax :	+ 27 (11) 201 2001'
                      label='Telephone'
                      path='/'
                    />
                    <CardItem
                      src=''
                      text='info@kgaboengineering.com'
                      fax=''
                      label='Email'
                      path='/'
                    />
                    
                  </ul>
                </div>
              </div>
        </div>
    )
};

export default ContactDetails;